/*
.breadcrumb-fixed{
  .main {
    background: #E9ECEF;
  }
}
 */

/*
.breadcrumb {
  background: #E9ECEF;
}
 */

.app-body{
  background: white;
}


div.card {
  border-color: #ED3B3B;
  border-width: .5px;
  background: #F2F2F2;
}
div.card-header {
  background-color: #ED3B3B;
  color: white;
  text-align: center;
  height: 55px;
}
div.card-body {
  border-color: #ED3B3B;
  border-width: thin;
}
div.card.p-4 {
  border-width: 0px;
}

div.card.text-white.py-5.d-md-down-none {
  background-color: #ED3B3B;
}

div.card-group {
  border-color: #ED3B3B;
}

table{
  /*display: block;*/
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/*
.table-striped>tr:nth-child(odd){
  background-color:white;
}
 */

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: #ffffff;
  color: #0c0e10;
}

.dropdown-menu {
  color: white;
}
.icon-bell {
  color: white;
}
.icon-list {
  color: white;
}
.icon-location-pin {
  color: white;
}
.fa {
  color: white;
}


@font-face {
  font-family: "FordAntenna-Light";
  src: url('../../assets/fonts/Antenna/FordAntenna-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FranklinGothic";
  src: url('../../assets/fonts/FranklinGothic/FranklinGothic.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Harabara';
  src: url('../../assets/fonts/Harabara/Harabara.woff2') format('woff2'),
       url('../../assets/fonts/Harabara/Harabara.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  
}

.col-md-12.tab-content {
  border: 1px solid #ED3B3B;
}




button.btn.btn-primary {
  background-color: #007BFF;
}

/*button.btn.btn-info.my-2.mx-2 {*/
button.btn.btn-info {
  background-color: #58A7FD;
}

button.btn.btn-danger {
  background-color: #ED3B3B;
}


label {
  font-weight: bold;
}




/*
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #007BFF;
  background-color: #007BFF;
}
 */





/* 007BFF */
/*
.checked ~ .custom-control-label::before {
  border-color: red;
  background-color: red;
}


checkbox.custom-control-input {
  border-color: red;
  background-color: red;
}
*/


.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007BFF!important;
}

.custom-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
  /*box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 255, 0, 0.25)
   */
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  /*box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25)

   */
}
.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  background-color: #58A7FD;
}


/*
.custom-control-input{
.checked ~ .custom-control-label::before {
  border-color: red;
  background-color: red;
 }
}
 */


/*
.focus:not(:checked) ~ .custom-control-label::before {
   border-color: $custom-control-indicator-focus-border-color;
 }

.not(:disabled):active ~ .custom-control-label::before {
   color: $custom-control-indicator-active-color;
   background-color: $custom-control-indicator-active-bg;
   border-color: $custom-control-indicator-active-border-color;
 @include box-shadow($custom-control-indicator-active-box-shadow);
 }

.disabled {
~ .custom-control-label {
  color: $custom-control-label-disabled-color;

.:before {
   background-color: $custom-control-indicator-disabled-bg;
 }
}
}
}
*/





/*
.table-striped>tbody>tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
  background-color: #ffffff;
  color: #0c0e10;
}

.tab-content {
    border: 1px solid #ED3B3B;
}


 */



/*
.mat-paginator {
  background: white;
  color: #20A8D8;
}
.mat-icon-button{
  border-radius: none;
  background-color: #20A8D8;
  height: 20px;
  width: 20px;
}
.mat-form-field {
  color: #20A8D8;
}
.mat-form-field-wrapper{
  color: #20A8D8;
}
.mat-select {
  color: #20A8D8;
}
.mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex {
  color: #20A8D8;
}
.mat-form-field-flex {
  color: #20A8D8;
}
*/





/*
@font-face {font-family: "Ford Antenna Light"; src: url("//db.onlinewebfonts.com/t/3cbd18ee75a23e404e78614692aa64e8.eot"); src: url("//db.onlinewebfonts.com/t/3cbd18ee75a23e404e78614692aa64e8.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/3cbd18ee75a23e404e78614692aa64e8.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/3cbd18ee75a23e404e78614692aa64e8.woff") format("woff"), url("//db.onlinewebfonts.com/t/3cbd18ee75a23e404e78614692aa64e8.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/3cbd18ee75a23e404e78614692aa64e8.svg#Ford Antenna Light") format("svg"); }
 */
